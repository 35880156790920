html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  /* Prevent scrolling */
}

.acceptance_criteria_gen {
  vertical-align: top;
  position: relative;
}

.acceptance_criteria_gen:hover::before {
  content: '\f044';
  font-family: 'Font Awesome 5 Free';
  position: absolute;
  right: 0px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
}

.acceptance_criteria_gen :hover {
  background-color: rgb(243, 242, 242);
}

.user_story_gen {
  vertical-align: top;
}

.editeTableContent {
  width: 100%;
  text-align: left;
}

.userStory {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.userStory :hover {
  background-color: rgb(243, 242, 242);
}

.userStorycheckBox {
  margin-right: 5px;
}

.test_cases_gen {
  vertical-align: top;
}

.action {
  vertical-align: middle;
}

.loader {
  margin-top: -70px;
  color: gray;
  font-size: 24px;

}

.loaderforDb {
  margin-top: -60px;
}


.btnWrap {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0 0;
}

.btnWrap>*:not(:first-child) {
  margin-left: 10px;
}

.ant-btn.ant-btn-default.btn-primary:not(:disabled) {
  border-color: #0540a5;
  background: #4153dd;
  color: #fff;
  margin-left: 5px;
}

.ant-btn.ant-btn-default.btn-primary:not(:disabled):hover {
  color: #fff;
  background: #407adb;
  border-color: #407adb;
  margin-left: 5px;
}

.ant-btn.ant-btn-default.btn-primary:focus-within {
  outline-color: #2e364c;
}

.uploadWrapper {
  padding: 16px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.uploadWrapper p {
  color: #000;
  margin-top: 0;
  margin-bottom: 1rem;
}

.uploadWrapper p:last-child {
  margin-bottom: 0;
}

.uploadWrapper .upload-hint {
  color: #666;
}

.uploadWrapper .material-symbols-outlined {
  font-size: 6rem;
  color: #407adb;
}

.drag {

  width: 23.5%;
  display: flex;
  justify-content: space-between;

}

.selectButtonWrap {
  margin-top: -60px;
  display: flex;
  justify-content: end;
  padding-right: 20px;

}

.customNav {
  display: flex;
  justify-content: center;
}

.logo {
  padding-left: 10px;
}

.centered-card {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;


}

.dbStreaming {

  padding: 22px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.vertical-text {
  writing-mode: vertical-lr;
  white-space: nowrap;
  transform: rotate(180deg);
  position: absolute;
  top: 11px;
  right: -24px;
  background: #fff;
  border-radius: 8px;
  cursor: pointer;
  padding: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.vertical-text:hover {
  box-shadow: 0 0 10px rgb(176 196 102 / 87%);
}

.buttonLoader {
  font-size: 22px;
  color: #fff;
  margin-right: 6px;
}

.selectBox {
  margin-right: 6px;
}

.userStoryMain {
  display: flex;
  justify-content: space-between;
}

.userStoryIcon {
  width: 60%;
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  padding-left: 10%;
  padding-right: 20%;
}

.newUserStory {
  color: green;
}

.newUserStory:hover::before {
  content: "Add a new userstory";
  display: block;
  position: absolute;
  top: 120%;
  left: 10%;
  transform: translateX(-50%);
  background-color: rgb(176, 211, 186);
  padding: 5px;
  border: 1px solid rgb(188, 209, 194);
  border-radius: 2px;
  font-size: 12px;
  color: #fff;
  z-index: 99999;
  pointer-events: none;
  font-weight: 800;
}

.pauseCircle {
  font-size: 20px;
}

.userStoryDeleteIcon {
  height: 3.2vh;
  margin-left: 10px;
  cursor: pointer;
}

.clickableDelete {
  cursor: pointer;
  color: red;
}

.notClickableDelete {
  color: gray;
}

.notClickableDelete:hover::before {
  content: "Pick an userstory first";
  display: block;
  position: absolute;
  top: 120%;
  left: 10%;
  transform: translateX(-50%);
  background-color: gray;
  padding: 5px;
  border: 1px solid gray;
  border-radius: 2px;
  font-size: 12px;
  color: #fff;
  z-index: 99999;
  pointer-events: none;
}

.clickableAcceptanceCriteria {
  cursor: pointer;
  color: rgb(69, 57, 231);
}

.clickableAcceptanceCriteria:hover::before {
  content: "Generate Acceptance Criteria";
  display: block;
  position: absolute;
  top: 120%;
  left: 0;
  background-color: rgb(175, 178, 219);
  padding: 5px;
  border: 1px solid rgb(175, 178, 219);
  border-radius: 2px;
  font-size: 12px;
  color: #fff;
  font-weight: 800;
  pointer-events: none;
}

.notClickableAcceptanceCriteria {
  color: gray;
}

.notClickableAcceptanceCriteria:hover::before {
  content: "Pick an userstory first";
  display: block;
  position: absolute;
  top: 120%;
  left: 15%;
  background-color: gray;
  padding: 5px;
  border: 1px solid gray;
  border-radius: 2px;
  font-size: 12px;
  color: #fff;
  pointer-events: none;
}

.navbar-nav {
  cursor: pointer;
}

.ant-upload-list-item {
  margin-left: 27px;
}

.newProjectButton {
  margin-bottom: 20px;
  position: absolute;
  right: 20px;
  background: #4285f4;
  background: linear-gradient(135deg, #9b72cb, #4285f4, #d96570);
  transition: background 0.3s ease;
  border: none;
  border-radius: 4px
}

.newProjectButton:hover {
  background: linear-gradient(135deg, #d96570, #4285f4, #9b72cb);
  /* Reverse gradient on hover */
}

.fldWrap {
  margin: 16px 12px 16px 2px;

}

.loginWrapperWhole {
  background-color: rgb(220, 249, 242);
  height: 93vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.registrationGenericContent {
  display: flex;
  align-items: center;
  width: 960px;
  max-width: 100%;
  margin: auto;
  background-color: white;
  box-shadow: 0px 16px 32px 0px rgba(117, 102, 128, 0.12), 0px 8px 16px 0px rgba(59, 48, 63, 0.12);
  border-radius: .2px;
  position: relative;
  justify-content: space-between;
}

.chatRegistrationGenericContent {
  max-width: 100%;
  width: 960px;
  margin: auto;
  background-color: white;
  box-shadow: 0px 16px 32px 0px rgba(117, 102, 128, 0.12), 0px 8px 16px 0px rgba(59, 48, 63, 0.12);
  border-radius: .2px;
  position: relative;
  min-height: 720px;
  overflow: auto;
}

.inputWrapper {
  position: absolute;
  bottom: 5px;
  right: 5px;
  left: 5px;
}

.leftImg {

  background: url("https://png.pngtree.com/png-vector/20220712/ourmid/pngtree-customer-assessment-businesswoman-company-control-png-image_5928919.png") no-repeat left / auto;
  flex: 0 0 320px;
  display: block;
  min-height: 720px;
  background-size: contain;
}

.rightArea {
  margin-left: 0;
  padding: 30px 70px;
  width: 100%;
}

.btnStyle1 {
  width: 100%;
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  font-size: 0.875rem;
  line-height: 20px;
  font-weight: 500;
  cursor: pointer;
  color: white;
  background-color: rgb(3, 46, 45);
  border: 1px solid;
  padding: 9px 12px;
  border-radius: 6px;
  text-decoration: none;
  min-width: 105px;
}

.inputBox {
  width: 100%;
  display: block;
  font-size: inherit;
  line-height: 24px;
  outline: none;
  font-family: inherit;
  font-weight: inherit;
  border-radius: 6px;
  padding: 7px 12px;
  margin: 0;
  background-color: whitesmoke;
  border: 1px solid rgb(199, 239, 221);
  color: var(--cs-primary-a);
  margin-bottom: 5px;
}

.rightChatArea {
  margin: 0;
  padding: 30px 70px;
  width: 100%;
  background-color: whitesmoke;
}


.inputWithIcon {
  width: 100%;
  padding: 10px;
  padding-right: 40px;
  padding-left: 40px;
  border: 1px solid #ccc;
  border-radius: 17px;
  font-size: 16px;
  height: 100%;
}

.iconRightEnablled {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: rgb(123, 236, 208);
  font-size: 24px;
  cursor: pointer;
}

.iconRightDissabled {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: rgb(228, 246, 245);
  font-size: 24px;
  cursor: not-allowed;
}

.chatMessages {
  overflow: auto;
  flex-grow: 1;
  margin-bottom: 20px;
  max-height: 90%;
  position: absolute;
  width: 100%;
  padding: 10px;
}

.messageWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.userMsg {
  background-color: #DCF8C6;
  /* Light green bubble for user message */
  padding: 10px;
  border-radius: 15px 15px 0 15px;
  margin: 10px 0;
  text-align: left;
  max-width: 80%;
  word-wrap: break-word;
  align-self: flex-end;
}

.AiMsg {
  background-color: #f1f0f0;
  padding: 10px;
  border-radius: 15px 15px 15px 0;
  margin: 10px 0;
  text-align: left;
  max-width: 80%;
  word-wrap: break-word;
  align-self: flex-start;
}

.iconLeft {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  color: rgb(123, 236, 208);
  font-size: 22px;

}

.chatImagePreview {
  width: 98%;
  position: absolute;
  height: 10%;
  padding: 9px;
  bottom: 46px;
  left: 6px;
  border-top: 1px solid #f5e6e6;
  display: flex;
  justify-content: space-between;
  background-color: white;
}

.closeIcon {
  color: red;
  cursor: pointer;

}

.userImg {
  width: 60%;
}

.AiMsg img {
  width: 50%;
}

.loginWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f0f2f5;
  padding: 20px;
}

.loginBlk {
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

.loginBlk h2 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

/* Styling for the form */
.ant-form-item {
  margin-bottom: 20px;
}

/* Input fields */
.loginBlk .ant-input,
.loginBlk .ant-input-password {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  font-size: 16px;
}

/* Styling for the button */
.fullWidth {
  width: 100%;
  background-color: #1890ff;
  border-color: #1890ff;
  color: white;
  padding: 6px;
  border-radius: 4px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.fullWidth:hover,
.fullWidth:focus {
  background-color: rgb(3, 46, 45);
  border-color: #40a9ff;
}

/* Styling for the "Forgot Password" section */
.forgotWrap {
  margin-bottom: 20px;
  text-align: right;
}

.forgotWrap div {
  color: #1890ff;
  cursor: pointer;
  font-size: 14px;
  transition: color 0.3s ease;
}

.forgotWrap div:hover {
  color: #40a9ff;
}

.codeOutlineIcon {
  margin-left: 5px;
}

.downLoadJsButton {
  position: absolute;
  right: 45px;
  top: 13px;
  display: flex;
}
.viewCodeModalSyncIcon
{
  color: #407adb;
  padding-right: 4px;
}

.ant-table-cell {
  vertical-align: top;
}

.downLoadIcon {
  position: absolute;
  top: 0;
  cursor: pointer;
}

.dissbledDownloadZip {
  cursor: not-allowed;
  margin: 10px;
  background-color: #cccccc;
  color: #666666;
  border: 1px solid #aaaaaa;
  opacity: 0.6;
  border-radius: 5px;
}


.EnabledDownloadZip {
  background-color: #03183a;
  margin: 10px;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  border-radius: 5px;
}

.container {
  width: 80%;
  margin: 20px auto;
  border: 1px solid #9b72cb;
  background: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
  height: 88vh;
}

.projectTable {

  margin-top: 60px;
  overflow: auto;
  height: 61%;
}
.colorSchemeWrapper {
  display: flex;
  gap: 5px;
}

.colorBox {
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
}

.ant-input {
  border: 1px solid #9b72cb
}

.ant-table-row {
  border-bottom: 2px solid #d96570 !important;
  /* Custom border color for each row */
  transition: box-shadow 0.3s ease-in-out;
  /* Smooth transition for hover effect */
}

/* Define the hover effect for the table rows */
.ant-table-row:hover {
  box-shadow: 0 4px 8px #9b72cb;
  border-bottom: 2px solid #d96570 !important;
}

/* Optional: Add a hover effect for the table cells for better user experience */
.ant-table-row td {
  transition: background-color 0.3s ease-in-out;
}

.ant-table-row:hover td {
  background-color: #f5f5f5;
}

.leftDownLoadBox {
  width: 218px;
  border: 1px solid #9b72cb;
  height: 35px;
  margin-bottom: 10px;
  padding: 5px;
  margin-left: 3px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.enabledColorChangeBox
{
  position: absolute;
  display: flex;
  width: 216px;
  justify-content: space-around;
  top: 0;
  left: 0;
  padding: 6px;
  /* background-color: red; */
  /* padding: 4px; */
}
.enabledColorChangeBox .colorPickerInput {
  width: 20px;
    height: 20px;
    border: 1px solid #ccc;
    padding: 0;
}
.colorChangeCheckIcon
{
  color: #728415;
}
.colorChangeCheckIconDisabled
{
opacity: 0.4;
cursor: not-allowed;
}
.colorChangeCloseIcon
{

color: #de515f;
}

.fileName {
  max-width: 152px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

.nav-bar {
  width: 220px;
  background: #f5f5f5;
  /* Dark background color */
  padding: 10px;
  color: #fff;
  font-family: 'Arial', sans-serif;
  height: calc(100vh - 163px);
  overflow: auto;


}

.search-box {
  margin-bottom: 10px;
  width: 218px;
  margin-left: 3px;
  margin-top: 10px;

}

.search-box .ant-input-affix-wrapper {
  background-color: #ffffff;
  color: rgb(12, 11, 11);
  border: 1px solid #9b72cb
}



.nav-items {
  margin-top: 20px;
}

.navItem {
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 4px;
  transition: background 0.3s;
  background-color: transparent;
  color: rgb(60, 54, 54);
}

.navItemDisabled {
  padding: 10px;
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 4px;
  transition: background 0.3s;
  background-color: transparent;
  color: rgb(139, 127, 127);
  cursor: not-allowed;
  pointer-events: none;
}

.navItemDisabled.active,
.navItem.active {
  background-color: #a8c7fa;
  color: #fff;

}

.navItem:hover {
  background-color: #d3e3fd;
  /* Hover background color */
}

.mainContainer {
  display: flex;
}

/* Main content styling */
.main-content {
  flex: 1;
  padding: 20px;
  color: #fff;
  background: #ffff;
  height: calc(100vh - 40px);
  display: flex;
  flex-direction: column;
}

.tabs {
  display: flex;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  background-color: #9b72cb;
  border-radius: 4px 4px 0 0;
  margin-right: 5px;
  cursor: pointer;
}

.tabDisabled {
  background-color: #d3d3d3;
  color: #7a7a7a;
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.6;
  padding: 10px 20px;
  border-radius: 4px 4px 0 0;
  margin-right: 5px;
  cursor: pointer;
}

.tab.active {
  background-color: #4285f4;
}

.content {
  flex: 1;
  background: linear-gradient(135deg, #9b72cb, #4285f4, #d96570);
  border-radius: 4px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 169px);
}

.content-textarea {
  flex: 1;
  background-color: #fff;
  border: none;
  color: rgb(27, 23, 23);
  font-size: 16px;
  padding: 10px;
  margin-bottom: 10px;
  resize: none;
  height: 66vh;
  width: 100%;
}

.save-button {
  background-color: #4285f4;
  border: none;
  color: white;
  padding: 7px 52px;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-end;
  margin: 2px;
}

/* .save-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.6;
} */
.saveCancelButtonWrap
{
  display: flex;
}

.cancelbutton {
  background-color: #d96570;
  border: none;
  color: white;
  padding: 7px 52px;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-end;
  margin: 2px;
}

.cancelbutton:hover {
  background-color: #de515f;

}

.save-button:hover {
  background-color: #357ae8;
}

.mainContentDiv {
  width: 100%;
}

/* Top navigation bar styling */
.top-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #f5f5f5;
  color: #9b72cb;
  border-bottom: 2px solid #9b72cb;
  cursor: pointer;
}

.user-section {
  font-size: 16px;
}


.tabNavigation {
  display: flex;
  margin-bottom: 20px;
}



.userStoryContainer {
  /*background-color: rgba(155, 114, 203, 0.2);*/
  /* Light purple with some transparency */
  color: white;
  padding: 20px;
  border-radius: 8px;
  width: 99%;
  font-family: Arial, sans-serif;
  border: 2px solid #9b72cb;
  margin: 5px;
  height: calc(100vh - 70px);
}

/* Tab Navigation styling */
.tabNavigation {
  display: flex;
  margin-bottom: 20px;
}

.tab {
  padding: 10px 20px;
  background-color: rgba(217, 101, 112, 0.8);
  margin-right: 5px;
  cursor: pointer;
  border-radius: 4px 4px 0 0;
}

.tab.active {
  background-color: #4285f4;
}


.userStoryTitle {
  padding: 10px;
  font-size: 18px;
  border: 2px solid #1157ce;
  border-radius: 4px;
  margin-bottom: 28px;
  color: #000;
  box-shadow: 0 2px 3px #9b72cb;

}


.description {
  /* background-color: rgba(217, 101, 112, 0.8);  */
  padding: 20px;
  font-size: 16px;
  border: 2px solid #d96570;
  border-radius: 4px;
  margin-bottom: 20px;
}


.criteriaContainer {
  display: flex;
  flex-direction: column;

}


.criteria {
  padding: 20px;
  border: 2px solid #9b72cb;
  border-radius: 4px;
  height: 29vh;
  max-height: fit-content;
  overflow: auto;
  position: relative;
}

.testCase {
  padding: 20px;
  border: 2px solid #9b72cb;
  border-radius: 4px;
  height: 28vh;
  max-height: fit-content;
  overflow: auto;
  position: relative;

}

.editIcon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
.testCaseIconWrap
{
  position: absolute;
  top: 2px;
  right: 0;
  cursor: pointer;
  display: flex; 
  justify-content: space-around;
}
.checkCircleIcon
{
  color:#728415;
  padding-right: 3px;
}
.closeCircleIcon
{
  color:#d96570;
}
.criteriaHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: 600;

}

.criteriaContent {
  font-size: 14px;
}

.criteriaIcon {
  font-size: 18px;
  color: #4285f4;
  cursor: pointer;
}

.requirementContainer {
  display: flex;
  flex-direction: column;

}

.collapseArrow {
  align-self: flex-end;
}

.userStoryTitleIconWrap {
  display: flex;
  cursor: pointer;
}

.userStoryMainContainer {
  display: flex;
  justify-content: space-between;
  padding: 5px;
  font-weight: 500;
  align-items: center;
  position: relative;
}

.mainWrapperChildContainer {
  height: calc(100vh - 170px);
  overflow: auto;
}

.htmlMockupButtonWrap {
  display: flex;
  justify-content: flex-end;
  color: #4285f4;
  position: fixed;
  right: 25px;
  top: 104px;
}

.deleteIconHover:hover {
  color: #d96570;
}

.uploadSpin {
  color: white;
}

.tooltipTitleIcon {
  font-size: '16px';
  color: '#ffffff';
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  padding: 5px;
  border-radius: 50%;
}

.tooltipTitleIcon:hover {
  box-shadow: 0px 0px 8px 4px rgba(0, 0, 0, 0.2);
  transform: scale(1.1);

}

.addPageIcon {
  color: #728415;
  cursor: pointer;
  margin: 3px;
}

.downloadProjectIcon {
  color: #9b72cb;
  cursor: pointer;
}



.downloadProjectIconDisabled {

  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed !important;
}

.addPageIcon:hover {
  color: #d96570;
  transform: scale(1.2);
  box-shadow: 0 4px 8px rgba(217, 101, 112, 0.5);
}


.downloadProjectIcon:hover {
  color: #d96570;
  transform: scale(1.2);
  box-shadow: 0 4px 8px rgba(217, 101, 112, 0.5);
}

/* .addPageInput
  {
      border-radius: 0!important;

  } */
.addPageInput:focus {
  border: 2px solid #9b72cb !important;
  outline: none !important;
  box-shadow: none !important;
}

.htmlMockupCode {
  color: #000;
}

.htmlMockupCodeTextArea {
  border: none;
  font-size: inherit;
  font-weight: 500;
}

.singleFileCodeIcon {
  color: #d96570;
}

.singleFileCodeIconDisabled {
  cursor: not-allowed;
  color: #aaaaaa;
}

.ant-popover-content {
  width: calc(100vw - 355px);
}

.sendPromptIcon {
  font-size: 20px;
}

.sendPromptIconDisabled {
  font-size: 20px;
  color: #aaaaaa;
}

.addUserStoryIcon {
  color: #728415;
  cursor: pointer;
  font-size: 20px;
  margin-bottom: 5px;
}
/* TipTap Editor */
.editorOptions
{
  background: #f3f2f2;
    width: fit-content;
    padding: 10px;
    position: absolute;
    right: 14px;
    z-index: 1;
}
.formatOptions
{
  display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: fit-content;
    gap: 10px;

}

.formatOptions button {
  background: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 16px;
}

.formatOptions button:active,
.formatOptions button.is_active {
  /* box-shadow: 5px 8px 10px rgba(133, 175, 209, 0.2); */
  /* transform: translateY(2px); */
  box-shadow: 0 6px 10px #9b72cb;
  color: #d96570;
}

.formatOptions button:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}
.ProseMirror-focused
{
  border: none!important;
}

/* Right Drawer */
.templateGallery
{
  text-align: center;
}
.templateGalleryCard
{
padding-bottom: 15px;
}

.templateGallery h2 {
  margin-bottom: 16px;
}

.templateList {
  overflow: auto;
  max-height: 529px;
  margin-bottom: 10px;
}

.templateCard {
  
  text-align: center;
  margin-bottom: 10px;
  
}
.applyButton {
  font-size: 20px;
  color: #d96570;
}
.applyButtonDisabled
{
  font-size: 20px;
  color: #e9c3c7;
  cursor:not-allowed;
}
.drawerFooter
{
  text-align: right;
}
.colorLists
{
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.colorPickerInput
{
  border: none;
  width: 20%;
  cursor: pointer;
}
.drawerTitle {
  display: flex;
  align-items: center;
}

.drawerArrowicon {
  margin-right: 8px; 
  color: #d96570;
}
.custom-carousel .slick-prev,
.custom-carousel .slick-next {
  color: #d96570;
  font-size: 13px;
}

.custom-carousel .slick-prev:hover,
.custom-carousel .slick-next:hover {
  color: red;
  font-size: 13px;
}


/* //news Anchor */

/* App.css */
.appContainer {
  display: flex;
  height: 100vh;
}

.leftNav {
  width: 15%;
  background-color: #f0f2f5;
}

.subNav {
  width: 20%;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  overflow: auto;
}

.mainContent {
  width: 65%;
  padding: 20px;
  background-color: #f7f2f2
}

.contentPreview {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
 
 
  font-size: 24px;
  font-weight: bold;
  position: relative;
}
.generateVideoButton {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #ff7f50;
  border-color: #ff7f50;
  color: #fff;
}

.generateVideoButton:disabled {
  opacity: 0.6; /* Lower opacity when the button is disabled */
  cursor: not-allowed;
}
.avtarImage
{
  display: flex;
  justify-content: space-between;
}

.scriptInputContainer {
  flex: 1;
}

.scriptInputBorder {
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.scriptTextarea {
 width: 94%;
  height: 200px;
  border: none;
  padding: 10px;
  font-size: 16px;
  line-height: 1.5;
  resize: none; /* Make the textarea non-resizable */
  box-shadow: none;
}

.previewLimitWarning {
  margin-top: 10px;
  background-color:rgb(255, 232, 214);
  align-items: flex-start;
  padding: 10px;
}
/* .videoPreview {
  width: 800px; 
  height: 600px; 
  border-radius: 8px;
  box-shadow: -10px 10px 15px rgb(255, 232, 214); 
} */

.videoContainer {
  position: relative;
  width: 800px; /* Increased width */
  height: 600px; /* Increased height */
}
.presenterViewWrap
{
  display: flex;
  flex-direction: column;
}
.presenterImagePreview
{
  position: relative;
  width: 650px; 
  height: 650px;
}
.videoPreview {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.presenterInfo
{
  background: #ffe8d6;
    font-weight: 400;
    font-size: 18px;
    padding: 10px;
}

.videoShadowOverlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%;
  background: linear-gradient(to top, rgba(255, 232, 214, 0.5), transparent); /* Custom shadow color */
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  pointer-events: none;
}

.newsFeedContainer {
  padding: 20px;
}

.newsCard {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.newsContent {
  flex-grow: 1;
}

.newsTitle {
  font-weight: bold;
  margin: 0;
}

.newsDescription {
  margin: 0;
}

.playIcon {
  font-size: 24px;
  color: #1890ff;
}
.voiceCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width:44%;
  height: 110px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.voiceName {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  font-weight: bold;
}

.voiceIcon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  font-size: 24px;
  color: #7d7b74;
  opacity: 0.4;
  cursor: not-allowed;
}
.voiceGrid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.customPanel {
  border: none !important;
  padding: 0 !important;
  background-color:white;
}

.ant-collapse-header {
  font-weight: bold;
  padding-left: 0;
}

.ant-collapse-content {
  border-top: none;
}

.voiceStyleCardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 20px;
}

.voiceStyleCard {
  position: relative;
  width: 45%;
  height: 110px;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.voiceStyleCard:hover {
  transform: scale(1.05);
}

.voiceStyleCardImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.voiceStyleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.voiceStyleSelect {
  width: 280px;
  padding: 10px;
  font-size: 16px;
  border: 2px solid #007bff;
  border-radius: 8px;
  background-color: #f8f9fa;
  color: #007bff;
  outline: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.voiceStyleSelect:hover {
  background-color: #007bff;
  color: #ffffff;
}

.voiceStyleSelect:focus {
  border-color: #0056b3;
}

.selectedEmoji {
  font-size: 64px;
  margin-top: 20px;
  transition: transform 0.3s ease;
}

.selectedEmoji:hover {
  transform: scale(1.1);
}

.popoverContent {
  display: flex;
  align-items: center;
  gap: 8px;
  color: white;
}

.navItemPophover {
  max-width: 150px;
 
}

/*code Generator*/

/* App.css */
.cgr_colabClone {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
}

.cgr_fileStructure {
  width: 280px;
  padding: 20px;
  border-right: 1px solid #e0e0e0;
  background-color: #f0f0f0;
  overflow-y: auto;
  white-space: nowrap;
}

.cgr_mainContent {
  flex: 1;
  padding: 20px;
  background-color: #f5f5f5;
  overflow: auto;
}

.cgr_colabHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.cgr_addCellButton {
  background-color: #4285f4;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.cgr_cellsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cgr_cell {
  background-color: #1e1e1e;
  padding: 10px;
  border-radius: 4px;
}

.cgr_cellActions {
  margin: 10px 0;
}

.cgr_runButton {
  background-color: #34a853;
  color: #fff;
  border: none;
  padding: 5px 15px;
  border-radius: 4px;
  cursor: pointer;
}

.cgr_outputContainer {
  background-color: #282c34;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 14px;
  white-space: pre-wrap;
}
.cgr_card {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  margin-bottom: 100px;
  padding-bottom: 30px;
}

.cgr_title {
  margin: 0 0 8px 0;
}

.cgr_codePreview {
  background-color: #f8f8f8;
  padding: 10px;
  border-radius: 4px;
  overflow: auto;
  white-space: pre-wrap;
}

.truncateFileName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 117px;
  display: inline-block;
}

.cgr_card.streaming {
  background-color: #408bcc; 
}


.cgr_codeLine {
  padding: 5px;
  font-family: monospace;
}
.fileManagementSpin
{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.highlighted {
  background-color: yellow; }

  .fade-in {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .fade-in-show {
    opacity: 1;
  }
  
  .fade-in-hide {
    opacity: 0;
  }
